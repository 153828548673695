<template>
  <div class="resources-wrapper p-3">
    <h1 class="title m-0">
      {{ title }}
    </h1>
    <div class="resources mt-1">
      <div v-for="(resource, index) of resources" :key="index">
        <a rel="follow" :href="resource.href" target="_blank">
          <div class="one-resource mt-2 d-flex align-items-center px-3">
            •
            {{ resource.label }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  resources: {
    type: Array,
    default: [],
  },
});

const resources = computed<any[]>(() => toRef(props.resources).value as any[]);
</script>

<style lang="sass" scoped>
.resources-wrapper
  background: #FFF
  color: #FFF
  border-radius: 18px
  .title
    font-size: 1.25rem !important
    color: #AE38E5
    font-weight: bold
  .one-resource
    width: 100%
    height: 28px
    border-radius: 14px
    background: #FBD7FD
    color: #214163
    @media(max-width: 500px)
      font-size: 13px
    @media(max-width: 349px)
      font-size: 12px

    &:hover
      background: #214163
      color: #FFF
</style>
